
import { onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
import { hideModal } from "@/core/helpers/dom";
import axios from "axios";

export default {
  name: "SearchBar",
  components: {},
  setup() {
    const searchGlobal = ref("");
    const modalSearch = ref<null | HTMLElement>(null);
    const router = useRouter();
    const brandsArray = ref<any>([]);
    const allYearsArray = ref<any>([]);
    const locations = ref<any>({
      options: [],
      searchable: true,
      value: null,
    });
    const years = ref<any>({
      options: [],
      searchable: true,
      value: null,
    });
    const brands = ref<any>({
      value: null,
      searchable: true,
      options: [],
    });
    const models = ref<any>({
      value: null,
      searchable: true,
      options: [],
    });
    const cost = ref<any>({
      options: [
        { value: "0 - 100000", label: "$0 - $100,000" },
        { value: "100000 - 200000", label: "$100,000 - $200,000" },
        { value: "200000 - 300000", label: "$200,000 - $300,000" },
        { value: "300000 - 400000", label: "$300,000 - $400,000" },
        { value: "400000 - 500000", label: "$400,000 - $500,000" },
        { value: "500000 - 600000", label: "$500,000 - $600,000" },
        { value: "600000 - 700000", label: "$600,000 - $700,000" },
        { value: "700000 - 800000", label: "$700,000 - $800,000" },
        { value: "800000 - 900000", label: "$800,000 - $900,000" },
        { value: "900000 - 1000000", label: "$900,000 - $1,000,000" },
      ],
      searchable: true,
      value: null,
    });
    const getyears = async () => {
      try {
        let yearsAll = <any>([]);
        
        const { data } = await ApiService.get("/api/site/years");
        years.value.options = data.data.map((y) => {
          yearsAll.push(y.year);
         return {
          value: y.year,
          label: y.year,
          }
        });
        allYearsArray.value = yearsAll
      } catch (error) {
        console.log("Error al obtener years");
      }
    };
    const getBrands = async () => {
      try {
        const { data } = await ApiService.get("/api/site/brands-model");
        brands.value = Object.values(data.data).map((e: any) => {
          if (e) {
            e.models = Object.values(e.models);
          }
          return e;
        });
        brandsArray.value = brands.value;
        brands.value.options = brands.value.map((y: any) => ({
          value: y.brand,
          label: y.brand,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    const getStates = async () => {
      let estados: any = [];
      await axios.get("/api/site/locations").then((response) => {
        const datos = Object.entries(response.data.data);
        datos.forEach((val, ind) => {
          val.forEach((val1: any, ind1) => {
            if (val1.state !== undefined) {
              if (estados.indexOf(val1.state) == -1) {
                estados.push(val1.state);
              }
            }
          });
        });
        estados.forEach((value, index) => {
          let objOption = {
            value: value,
            lable: value,
          };
          locations.value.options.push(objOption);
        });
      });
      /* console.log("ESTADOS", data);
        data.value.data.forEach((val, ind) => {
        }); */
      /* locations.value.options = data.data.map((y:any) => ({
          value: y.state,
          label: y.state,
        })); */
    };
    const getModels = async (val) => {
      models.value.value = "";
      models.value.options = "";
      brandsArray.value.forEach((e) => {
        if (val == e.brand) {
          models.value.options = e.models.map((m: any) => {
            return { value: m.model, label: m.model };
          });
        }
      });
    };

    const sendQuery = () => {

      let url = "/catalogo";
      if (years.value.value) {
        const yearsArray = allYearsArray.value.filter(item => item >= years.value.value)
        url += "?year=" + yearsArray;
      }
      if (brands.value.value) {
        url += url.includes("?") ? "&" : "?";
        url += "brand=" + brands.value.value;
      }
      if (models.value.value) {
        url += url.includes("?") ? "&" : "?";
        url += "model=" + models.value.value;
      }
      if (cost.value.value) {
        url += url.includes("?") ? "&" : "?";
        url += "price=" + cost.value.value;
      }
      if (locations.value.value) {
        url += url.includes("?") ? "&" : "?";
        url += "state=" + locations.value.value;
      }
      if (searchGlobal.value.length > 0) {
        url += url.includes("?") ? "&" : "?";
        url += "search=" + searchGlobal.value;
      }
      hideModal(modalSearch.value);
      router.push(url);
    };

    onMounted(async () => {
      await Promise.all([getyears(), getBrands(), getStates()]);
    });

    return {
      locations,
      years,
      brands,
      models,
      cost,
      searchGlobal,
      getModels,
      sendQuery,
      modalSearch,
    };
  },
};
