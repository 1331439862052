
import { useRouter } from "vue-router";

export default {
  name: "Brands",
  setup() {
    const router = useRouter();
    const brands = [
      {
        name: "Hyundai",
        logo: "HYUNDAI.svg",
        minPrice: "$40",
      },
      {
        name: "JAC",
        logo: "JAC.svg",
        minPrice: "$60",
      },
      {
        name: "Toyota",
        logo: "Toyota.svg",
        minPrice: "$60",
      },
      {
        name: "Volkswagen",
        logo: "VW.svg",
        minPrice: "$80",
      },
      {
        name: "Kia",
        logo: "KIA.svg",
        minPrice: "$80",
      },
      {
        name: "Suzuki",
        logo: "SUZUKI.svg",
        minPrice: "$75",
      },
    ];
    const sendQuery = (brand) => {
      let url = "/catalogo";
      if (brand) {
        url += url.includes("?") ? "&" : "?";
        url += "brand=" + brand;
        router.push(url);
      }
    };
    return {
      brands,
      sendQuery,
    };
  },
};
