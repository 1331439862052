/* eslint-disable */
<template>
  <div class="position-relative mx-auto mt-4">
    <div
      class="w-100 bg-croped position-relative"
    >
    <img :src="imgSrc" alt="" class="imgBanner"/>
      <!-- <img :src="imgSrc" alt="" class="w-100 rounded-custom img-fulled d-none d-lg-block container-xxl" /> -->
      <!-- <div class="components position-relative col-12 d-block d-lg-none">
            <img src="/media/images/home/Fondo.png" class="h-100 w-100" alt="fondo">
            <img src="/media/images/home/F1_AO.png" class="position-absolute f1" alt="F!">
            <img src="/media/images/home/F2_AC.png" class="position-absolute f2" alt="F2">
            <img src="/media/images/home/Honda-Car-Background-PNG-Image.png" class="position-absolute car" alt="fondo">
        </div> -->
    </div>
    <!-- <h1
        class="text-big-custom position-absolute  w-md-50 top-25 text-start px-5 d-block d-lg-none"
      >
        <span>Te cambiamos tu vehículo por uno nuevo <br /></span> 
        <span class="text-big-sec fw-normal"
          >Descervigar Engargolar Increpador Descentrar Abajadero
          Incrédulamente.
        </span>
        <br />
        <button class="btn btn-primary mt-2">Conocer mas</button>
      </h1> -->
  </div>
  <div class="col-12 container-xxl">
    <div class="row">
      <Search />
      <div class="mx-auto">
        <Carousel
          v-if="vehicles.length > 0"
          :vehicles="vehicles"
          title="Los más vistos"
        />
        <Brands />
        <h1 class="fw-bold text-center my-4">Contamos con sucursales a lo largo del país</h1>
        <router-link to="/catalogo">
          <p class="fs-5 link-blue text-center">Conoce tu sucursal más cercana</p>
        </router-link>
      </div>
    </div>
  </div>
  <div ref="mapDiv" class="rounded mb-5" style="width: 100vw; height: 600px" id="map"></div>
  <!-- <img src="/media/images/home/Mapa.png" alt="" class="img-fluid d-none d-md-block rounded-custom mb-7 mt-2" />
      <img src="/media/images/home/Mapa_mov.png" alt="" class="img-fluid d-block d-md-none rounded-custom mb-7 mt-2" /> -->
  <div class="col-12 container-xxl">
    <Carousel
      v-if="similares.length > 0"
      :vehicles="similares"
      title="Agregados recientemente"
    />
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { onMounted, ref, onUnmounted } from "vue";
import Search from "@/views/client/components/Search.vue";
import Carousel from "@/views/client/components/Carousel.vue";
import Brands from "./components/Brands.vue";
import axios from "axios";
import { Loader } from "@googlemaps/js-api-loader";
import ApiService from "@/core/services/ApiService";

const API_KEY = "AIzaSyACDXm5WBEngJZHLoEiSzzYCdlkg3THQmI";

export default {
  name: "Home",
  components: {
    Search,
    Carousel,
    Brands,
  },
  setup() {
    const imgSrc = ref();
    const vehicles = ref([]);
    const similares = ref([]);
    const banners = ref([]);
    const idConfig = ref(1);
    const recents = ref([]);
    const mapDiv = ref(null);
    const getVehicles = async () => {
      try {
        const { data } = await axios.get("/api/site/recent");
        if (data.data.Vehicles.length > 0) {
          similares.value = data.data.Vehicles.slice(0, 3);
        }
        const { data: _data } = await axios.get("/api/site/popular-vehicles");
        if (_data.data.Vehicles.length > 0) {
          vehicles.value = _data.data.Vehicles.slice(0, 3);
        }
        const { data: __data } = await axios.get(`/api/site/banners/${idConfig.value}`);
        console.log("data", __data)
        if (_data.data.Vehicles.length > 0) {
          banners.value = __data.data;
        }
      } catch (error) {
        throw new Error("Error al obtener vehiculos");
      }
    };
    const loader = new Loader({ apiKey: API_KEY });
    const map = ref();
    const agencies = ref([]);

    // const getAgencies = async()=>{
    //   try {
    //     const {data} = await ApiService.get('/api/site/agencies');
    //     console.log("getAgencies", data)
    //     agencies.value = data.data.agency.data;
    //   } catch (error) {
    //     console.log(error)
    //   }

    // }
    const onResize = (e) => {
      windowSize.value = window.innerWidth;
      if (windowSize.value >= 100) {
        imgSrc.value = banners.value.image320  === null ? banners.value.image768 : banners.value.image320;
      }
      if (windowSize.value >= 425) {
        imgSrc.value = banners.value.image768 === null ? banners.value.image1440 : banners.value.image768 ;
      }
      if (windowSize.value >= 768) {
        imgSrc.value = banners.value.image1440;
      }
    };

    const windowSize = ref(1920);
    onUnmounted(() => {
      window.removeEventListener("resize", onResize);
    });

    onMounted(async () => {
      window.addEventListener("resize", onResize);
      windowSize.value = window.innerWidth;

      await getVehicles();
      //   await getAgencies();

      if (windowSize.value >= 100) {
        imgSrc.value = banners.value.image320  === null ? banners.value.image768 : banners.value.image320;
      }
      if (windowSize.value >= 425) {
        imgSrc.value = banners.value.image768 === null ? banners.value.image1440 : banners.value.image768 ;
      }
      if (windowSize.value >= 768) {
        imgSrc.value = banners.value.image1440;
      }
      
     
      await loader.load();
      map.value = new google.maps.Map(mapDiv.value, {
        center: { lat: 20.724671, lng: -103.3471888 },
        zoom: 5,
      });

      let url =  process.env.VUE_APP_API_URL+'/api/site/agenciesgm';
      const {data} = await ApiService.get(url);

      let newAgencies = [];
      if(data.success){
        if(data.data.length > 0){
          data.data.forEach(agency => {
            if(agency.latitude != '' && agency.longitude != ''){
              newAgencies.push(agency);
            }
          });
        }
      }

      agencies.value = newAgencies;

      /* agencies.value = [
        {
          address: "San Guadalupe 32",
          city: "Hermosillo",
          colonia: "Colonia 1",
          cp: "32232",
          email: "Solana@turnmyapp.com",
          id: 1,
          id_state: 26,
          latitude: "20.7069772",
          longitude: "-103.4196543",
          name: "Grupo Solana",
          phone: "3223322323",
          state: "Sonora",
          url_whatsapp:
            "https://api.whatsapp.com/send?phone=3323434344&text=Esto%20es%20una%20prueba%20",
        },
        {
          address: "San Guadalupe 32",
          city: "Hermosillo",
          colonia: "Colonia 1",
          cp: "32232",
          email: "Solana@turnmyapp.com",
          id: 1,
          id_state: 26,
          latitude: "20.0068023",
          longitude: "-102.2836754",
          name: "Solana Zamora",
          phone: "3223322323",
          state: "Sonora",
          url_whatsapp:
            "https://api.whatsapp.com/send?phone=3323434344&text=Esto%20es%20una%20prueba%20",
        },
      ]; */
      agencies.value.forEach((agency) => {
        if (agency.latitude && agency.longitude) {
          const marker = new google.maps.Marker({
            position: {
              lat: parseFloat(agency.latitude),
              lng: parseFloat(agency.longitude),
            },
            title: agency.name,
            map: map.value,
            icon: {
              scaledSize: new google.maps.Size(32, 32),
              url: "media/icons/iconsax/location-red.png",
            },
          });

          // map.value.panTo({ lat:parseFloat(agency.latitude), lng:parseFloat(agency.longitude) });

          const infowindow = new google.maps.InfoWindow({
            content: `<div id="content" class="content_marker px-4">
                    <div id="bodyContent">
                      <h5 class="text-primary">${agency.name}</h5>
                      <p class="text-gray-100">
                      ${agency.address === undefined ? "" : agency.address +"," }
                      ${agency.city === undefined ? "" : agency.city + ", "}
                      ${agency.state === undefined ? "" : agency.state}
                       </p>
                    </div>
                  </div>`,
          });

          marker.addListener("click", function () {
            infowindow.open(map, this);
          });

        //   marker.addListener("mouseout", function() {
        //     infowindow.close();
        //   });
        }
      });
    });
    return { imgSrc, vehicles, recents, mapDiv, similares, windowSize, idConfig, banners };
  },
};
</script>

<style lang="scss" scoped>
.container-img-fulled {
  background: rgb(239, 242, 246);
  background: linear-gradient(
    90deg,
    rgba(239, 242, 246, 1) 41%,
    rgba(10, 74, 179, 1) 41%,
    rgba(8, 96, 240, 1) 44%
  );
}
.top-25 {
  @media screen and (max-width: 500px) {
    top: 5% !important;
  }
}
.text-big-custom {
  font-weight: bolder;
  color: #393840;
  font-size: 40px;
  @media screen and (max-width: 1080px) {
    font-size: 27px;
  }
  @media screen and (max-width: 500px) {
    font-size: 18px;
  }
  left: 5%;
}
.text-big-sec {
  font-size: 20px;
  @media screen and (max-width: 1080px) {
    font-size: 18px;
  }
  @media screen and (max-width: 500px) {
    font-size: 14px;
    margin-top: 10px;
  }
}
.components {
  height: 600px;
  @media screen and (max-width: 500px) {
    height: 420px;
  }
  overflow: hidden;
}
.car {
  bottom: 0;
  right: 0;
  @media screen and (max-width: 500px) {
    right: -30px;
    bottom: 5%;
    width: 80%;
  }
}
.f1 {
  right: 5%;
  bottom: 0;
  @media screen and (max-width: 500px) {
    right: 0%;
    bottom: 0;
  }
}
.f2 {
  right: -15em;
  bottom: -46px;
  @media screen and (max-width: 500px) {
    right: -50%;
    bottom: -60px;
  }
}
.bg-croped {
  width: 100%;
  position:relative;
}
.imgBanner{
  width: 100%;
  height: auto;
  
}
// .bg-croped {
//   // transition: 0.2s ease-in-out;
//   width: 100vw;
//   min-height: 600px;
//   background-size: cover;
//   background-position: center center;
//   background-repeat: no-repeat;
  
//   @media screen and (max-width: 992px) {
//     min-height: 800px;
//     background-position: center top;
//   }
//   @media screen and (max-width: 500px) {
//     min-height: 400px;
//     background-size: 100%;
//   }
  
// }
</style>
